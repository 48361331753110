import type { RealtimeTrackDTO, TrackDTO } from 'src/services/supabase/queries'
import type { NormalizedTrack } from 'src/store/types'
import type { Track } from '../../domain/Track'
import type { TrackMode } from '../../domain/TrackMode'
import { TrackModeMap } from '../TrackModeMap'

export const fromTrackDTO = (track: TrackDTO): Track => {
  const trackModeDTO = track.track_mode.find((mode) => mode.active)
  if (!trackModeDTO) {
    throw new Error('Track mode not found')
  }

  return {
    uuid: track.id,
    description: track.description,
    active: track.active,
    showInLobby: track.show_in_lobby,
    createdAt: track.created_at,
    image: track.image,
    poster: track.poster,
    background: track.background,
    videoPreviewMp4: track.video_preview_mp4,
    videoPreviewWebm: track.video_preview_webm,
    name: track.name,
    shortName: track.short_name,
    mode: TrackModeMap.fromTrackModeDTO(trackModeDTO)
  }
}

export const fromRealtimeTrackDTO = (track: RealtimeTrackDTO, mode: TrackMode): Track => {
  return {
    uuid: track.id,
    description: track.description,
    active: track.active,
    showInLobby: track.show_in_lobby,
    createdAt: track.created_at,
    image: track.image,
    poster: track.poster,
    background: track.background,
    videoPreviewMp4: track.video_preview_mp4,
    videoPreviewWebm: track.video_preview_webm,
    name: track.name,
    shortName: track.short_name,
    mode
  }
}

export const fromNormalizedTrack = (track: NormalizedTrack, mode: TrackMode): Track => {
  return {
    uuid: track.uuid,
    name: track.name,
    shortName: track.shortName,
    description: track.description,
    active: track.active,
    showInLobby: track.showInLobby,
    createdAt: track.createdAt,
    image: track.image,
    poster: track.poster,
    background: track.background,
    videoPreviewMp4: track.videoPreviewMp4,
    videoPreviewWebm: track.videoPreviewWebm,
    mode
  }
}

export const toNormalizedTrack = (track: Track): NormalizedTrack => {
  return {
    ...track,
    modeId: track.mode.uuid
  }
}
