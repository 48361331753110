import React, { useEffect, useState } from 'react';
import { getAllOdds } from 'src/actions/getAllOdds';
import type { OddsResult } from 'src/utils/calculationService';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
const HybridRTPSection = ({
  totalMarbles = 8,
  maxPickableMarbles = 7
}) => {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [odds, setOdds] = useState<{
    singleMarble?: OddsResult;
    maxMarbles?: OddsResult;
    straightForecast?: OddsResult;
    reverseForecast?: OddsResult;
    tricast?: OddsResult;
    combinationTricast?: OddsResult;
  }>({});
  const calculateRTP = (odds: OddsResult | undefined) => {
    if (!odds) return '...';
    const rtp = 100 - (odds.odds - odds.oddsAfterMargin) / odds.odds * 100;
    return `${rtp.toFixed(2)}%`;
  };
  useEffect(() => {
    const fetchOdds = async () => {
      const singleMarbleOdds = await getAllOdds({
        totalItemsSelected: 1,
        totalItems: totalMarbles
      });
      const maxMarblesOdds = await getAllOdds({
        totalItemsSelected: maxPickableMarbles,
        totalItems: totalMarbles
      });
      const forecastOdds = await getAllOdds({
        totalItemsSelected: 2,
        totalItems: totalMarbles
      });
      const tricastOdds = await getAllOdds({
        totalItemsSelected: 3,
        totalItems: totalMarbles
      });
      setOdds({
        singleMarble: singleMarbleOdds.PickWinner,
        maxMarbles: maxMarblesOdds.PickWinner,
        straightForecast: forecastOdds.StraightForecast,
        reverseForecast: forecastOdds.ReverseForecast,
        tricast: tricastOdds.Tricast,
        combinationTricast: tricastOdds.CombinationTricast
      });
    };
    fetchOdds();
  }, [totalMarbles, maxPickableMarbles]);
  return <>
      <h4 className="yellow">{t('Pick Winner Mode')}</h4>
      <div className="rtp-table">
        <table>
          <thead>
            <tr>
              <th>{t('Selections')}</th>
              <th>{t('Payout')}</th>
              <th>{t('RTP')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('1 Marble')}</td>
              <td>
                {odds.singleMarble ? `${odds.singleMarble.oddsAfterMargin.toFixed(2)}x` : '...'}
              </td>
              <td>{calculateRTP(odds.singleMarble)}</td>
            </tr>
            <tr>
              <td>{t(`{{maxPickableMarbles}} Marbles`, {
                maxPickableMarbles
              })}</td>
              <td>{odds.maxMarbles ? `${odds.maxMarbles.oddsAfterMargin.toFixed(2)}x` : '...'}</td>
              <td>{calculateRTP(odds.maxMarbles)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 className="tomato">{t('Pick Order (2 Marbles)')}</h4>
      <div className="rtp-table">
        <table>
          <thead>
            <tr>
              <th>{t('Type')}</th>
              <th>{t('Payout')}</th>
              <th>{t('RTP')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('Any Order')}</td>
              <td>
                {odds.reverseForecast ? `${odds.reverseForecast.oddsAfterMargin.toFixed(2)}x` : '...'}
              </td>
              <td>{calculateRTP(odds.reverseForecast)}</td>
            </tr>
            <tr>
              <td>{t('Exact Order')}</td>
              <td>
                {odds.straightForecast ? `${odds.straightForecast.oddsAfterMargin.toFixed(2)}x` : '...'}
              </td>
              <td>{calculateRTP(odds.straightForecast)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 className="purple">{t('Pick Order (3 Marbles)')}</h4>
      <div className="rtp-table">
        <table>
          <thead>
            <tr>
              <th>{t('Type')}</th>
              <th>{t('Payout')}</th>
              <th>{t('RTP')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('Any Order')}</td>
              <td>
                {odds.combinationTricast ? `${odds.combinationTricast.oddsAfterMargin.toFixed(2)}x` : '...'}
              </td>
              <td>{calculateRTP(odds.combinationTricast)}</td>
            </tr>
            <tr>
              <td>{t('Exact Order')}</td>
              <td>{odds.tricast ? `${odds.tricast.oddsAfterMargin.toFixed(2)}x` : '...'}</td>
              <td>{calculateRTP(odds.tricast)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>;
};
export default HybridRTPSection;