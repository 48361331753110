import VolumeIcon from 'src/app/assets/icons/ico-volume.svg';
import VolumeMuteIcon from 'src/app/assets/icons/ico-volume-mute.svg';
import { useDebounce } from 'src/hooks/useDebounce';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
type Props = Readonly<{
  label: string;
  volume: number;
  setVolume: (volume: number) => void;
  isMuted: boolean;
  toggleMute: () => void;
  muteIcon?: React.ComponentType;
  volumeIcon?: React.ComponentType;
}>;
export function useThrottle(value: number, interval = 500) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastUpdated = useRef<number | null>(null);
  useEffect(() => {
    const now = Date.now();
    if (lastUpdated.current && now >= lastUpdated.current + interval) {
      lastUpdated.current = now;
      setThrottledValue(value);
    } else {
      const id = window.setTimeout(() => {
        lastUpdated.current = now;
        setThrottledValue(value);
      }, interval);
      return () => window.clearTimeout(id);
    }
  }, [value, interval]);
  return throttledValue;
}
export function VolumeControl({
  label,
  volume,
  setVolume,
  isMuted,
  toggleMute,
  muteIcon: CustomMuteIcon,
  volumeIcon: CustomVolumeIcon
}: Props) {
  const [inputVolume, setInputVolume] = useState<number>(volume);
  const MuteIcon = CustomMuteIcon || VolumeMuteIcon;
  const VolumeIconToUse = CustomVolumeIcon || VolumeIcon;
  const throttledVolume = useThrottle(inputVolume, 75);
  useEffect(() => {
    setVolume(throttledVolume);
  }, [throttledVolume]);
  return <label data-sentry-component="VolumeControl" data-sentry-source-file="VolumeControl.tsx">
      <span className="label">{label}</span>
      <button className={`inline-btn ${isMuted ? 'muted' : ''}`} onClick={toggleMute} type="button">
        {isMuted ? <MuteIcon /> : <VolumeIconToUse />}
      </button>
      <input max="1" step={0.01} min="0" onChange={e => {
      setInputVolume(Number(e.target.value));
    }} type="range" value={inputVolume} />
      <span className="volume-value">{Math.round(inputVolume * 100)}%</span>
    </label>;
}