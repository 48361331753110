import Image from 'next/image';
import { useState } from 'react';
type Props = Readonly<{
  trackName: string;
  previewThumbnail?: string;
  trackImage: string;
  videoPreviewMp4: string | null;
  videoPreviewWebm: string | null;
}>;
export function GameCardBackgroundImage({
  trackName,
  previewThumbnail,
  trackImage,
  videoPreviewMp4,
  videoPreviewWebm
}: Props) {
  const [isHovering, setIsHovering] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const hasVideoPreview = videoPreviewMp4 || videoPreviewWebm;
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
    setTimeout(() => {
      setIsVideoLoaded(false);
    }, 300);
  };
  return <div className="entry-image-container" onMouseEnter={() => setIsHovering(true)} onMouseLeave={handleMouseLeave} data-sentry-component="GameCardBackgroundImage" data-sentry-source-file="GameCardBackgroundImage.tsx">
      {/* Main display image */}
      <Image alt={`${trackName} Thumbnail`} className={`track-image ${hasVideoPreview && isHovering && isVideoLoaded || !hasVideoPreview && isHovering && previewThumbnail ? 'opacity-0' : 'opacity-100'}`} draggable="false" height={320} sizes="100vw" src={trackImage} style={{
      objectFit: 'cover'
    }} width={400} data-sentry-element="Image" data-sentry-source-file="GameCardBackgroundImage.tsx" />

      {/* Video preview if available */}
      {hasVideoPreview && (isHovering || isVideoLoaded) && <video className={`track-video ${isHovering && isVideoLoaded ? 'opacity-100' : 'opacity-0'}`} autoPlay loop muted playsInline onLoadedData={handleVideoLoad}>
          {videoPreviewMp4 && <source src={videoPreviewMp4} type="video/mp4" />}
          {videoPreviewWebm && <source src={videoPreviewWebm} type="video/webm" />}
        </video>}

      {/* Preview thumbnail for non-video tracks */}
      {!hasVideoPreview && previewThumbnail && <Image alt={`${trackName} Preview Thumbnail`} className={`preview-thumbnail ${isHovering ? 'opacity-100' : 'opacity-0'}`} draggable="false" height={320} sizes="100vw" src={previewThumbnail} style={{
      objectFit: 'cover'
    }} width={400} />}

      {/* Background image */}
      <Image alt={`${trackName} Thumbnail`} className="preview-image" draggable="false" height={320} sizes="100vw" src={trackImage} style={{
      objectFit: 'cover'
    }} width={400} data-sentry-element="Image" data-sentry-source-file="GameCardBackgroundImage.tsx" />
    </div>;
}