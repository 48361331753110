import type { ButtonHTMLAttributes } from 'react';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
type Props = Readonly<{
  onClick: () => void;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
}>;
export function ClearButton({
  onClick,
  children,
  className,
  type = 'button'
}: Props) {
  const {
    playEffect
  } = useSoundEffect();
  return <button className={className} onClick={() => {
    playEffect('click');
    onClick();
  }} type={type} data-sentry-component="ClearButton" data-sentry-source-file="index.tsx">
      {children}
    </button>;
}