import { useSoundEffect } from 'src/hooks/useSoundEffect';
type Props = Readonly<{
  href: string;
  children: React.ReactNode;
  className?: string;
  label?: string;
}>;
export function ExternalLink({
  href,
  children,
  className,
  label
}: Props) {
  const {
    playEffect
  } = useSoundEffect();
  return <a className={`button ${className}`} href={href} onClick={() => {
    playEffect('click');
  }} rel="noreferrer" target="_blank" data-sentry-component="ExternalLink" data-sentry-source-file="index.tsx">
      {label && <span className="label">{label}</span>}
      {children}
    </a>;
}