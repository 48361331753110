import ExpandIcon from 'src/app/assets/icons/ico-expand.svg';
type Props = Readonly<{
  onClick: () => void;
  className?: string;
}>;
export function FullScreenButton({
  onClick,
  className
}: Props) {
  return <button className={`game-toolbar-btn mobile-hidden ${className}`} onClick={onClick} type="button" data-sentry-component="FullScreenButton" data-sentry-source-file="FullScreenButton.tsx">
      <ExpandIcon data-sentry-element="ExpandIcon" data-sentry-source-file="FullScreenButton.tsx" />
    </button>;
}