import { useGetGameIds, useGetGameById } from 'src/store/game';
import { isUnknownGame } from 'src/types/domain/Game';
import { ShowGames } from './ShowGames';
type Props = Readonly<{
  title?: string;
}>;
export function ShowGamesContainer({
  title
}: Props) {
  const gameIds = useGetGameIds();
  const visibleGames = gameIds.filter(id => {
    const game = useGetGameById(id);
    return !isUnknownGame(game) && game.track.showInLobby;
  });
  return <ShowGames gameIds={visibleGames} title={title} data-sentry-element="ShowGames" data-sentry-component="ShowGamesContainer" data-sentry-source-file="ShowGamesContainer.tsx" />;
}