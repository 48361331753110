import { useRef, useEffect, useCallback } from 'react';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import TimesIcon from 'src/app/assets/icons/ico-times.svg';
import MusicIcon from 'src/app/assets/icons/ico-music.svg';
import MusicSlashIcon from 'src/app/assets/icons/ico-music-slash.svg';
import MicrophoneIcon from 'src/app/assets/icons/ico-microphone-lines.svg';
import MicrophoneSlashIcon from 'src/app/assets/icons/ico-microphone-lines-slash.svg';
import WaveformIcon from 'src/app/assets/icons/ico-waveform-lines.svg';
import { useGetAllSoundSettings, useSoundActions } from 'src/store/sound';
import { Button } from './Button';
import { VolumeControl } from './VolumeControl';
export default function AudioControlsDialog({
  isOpen,
  onClose
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const panelRef = useRef<HTMLDivElement>(null);
  const currentLanguage = useGetCurrentLanguage();
  const {
    t
  } = useClientSideTranslation(currentLanguage);
  const {
    volumes,
    muted
  } = useGetAllSoundSettings();
  const actions = useSoundActions();
  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as Element;
    const isVolumeButton = target.closest('.game-toolbar-btn');
    if (panelRef.current && !panelRef.current.contains(event.target as Node) && !isVolumeButton && isOpen) {
      onClose();
    }
  }, [isOpen, onClose]);
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);
  if (!isOpen) return null;
  return <div className="audio-controls-panel" ref={panelRef} data-sentry-component="AudioControlsDialog" data-sentry-source-file="AudioControlsDialog.tsx">
      <div className="panel-content_section">
        <div className="panel-header">
          <h3>{t('Audio Controls')}</h3>
          <Button className="close-button" onClick={onClose} useDefaultClasses={false} data-sentry-element="Button" data-sentry-source-file="AudioControlsDialog.tsx">
            <TimesIcon data-sentry-element="TimesIcon" data-sentry-source-file="AudioControlsDialog.tsx" />
          </Button>
        </div>
        <div className="panel-audio-controls">
          <div className="panel-audio-controls__section">
            <VolumeControl isMuted={muted.master} label={t('Master')} setVolume={v => {
            actions.changeMasterVolume(v);
          }} toggleMute={() => {
            muted.master ? actions.unmuteMaster() : actions.muteMaster();
          }} volume={volumes.master} data-sentry-element="VolumeControl" data-sentry-source-file="AudioControlsDialog.tsx" />
          </div>
          <div className="panel-audio-controls__section">
            <VolumeControl isMuted={muted.race} label={t('Race')} muteIcon={MicrophoneSlashIcon} setVolume={v => {
            actions.changeRaceVolume(v);
          }} toggleMute={() => {
            muted.race ? actions.unmuteRace() : actions.muteRace();
          }} volume={volumes.race} volumeIcon={MicrophoneIcon} data-sentry-element="VolumeControl" data-sentry-source-file="AudioControlsDialog.tsx" />
            <VolumeControl isMuted={muted.effects} label={t('Sounds')} muteIcon={WaveformIcon} setVolume={v => {
            actions.changeEffectsVolume(v);
          }} toggleMute={() => {
            muted.effects ? actions.unmuteEffects() : actions.muteEffects();
          }} volume={volumes.effects} volumeIcon={WaveformIcon} data-sentry-element="VolumeControl" data-sentry-source-file="AudioControlsDialog.tsx" />
            <VolumeControl isMuted={muted.music} label={t('Music')} muteIcon={MusicSlashIcon} setVolume={v => {
            actions.changeMusicVolume(v);
          }} toggleMute={() => {
            muted.music ? actions.unmuteMusic() : actions.muteMusic();
          }} volume={volumes.music} volumeIcon={MusicIcon} data-sentry-element="VolumeControl" data-sentry-source-file="AudioControlsDialog.tsx" />
          </div>
        </div>
      </div>
    </div>;
}