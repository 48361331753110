import { create } from 'zustand'
import { setAutoFreeze } from 'immer'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { pipe, Array as A, Option as O } from 'effect'
import { unknownPlayer, isAuthenticatedPlayer, anonymousPlayer } from 'src/types/domain/Player'
import type { Balance, Player } from 'src/types/domain/Player'
import type { Bet } from 'src/types/domain/Bet'
import { getValidDecodedToken } from 'src/utils/getValidDecodedToken'
import { betStatusMap } from 'src/types/domain/BetStatus'
import { useDemoStore } from './demo'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'
import { useBetStore } from './bet'

setAutoFreeze(false)

interface PlayerState {
  currentPlayer: Player
}

export interface PlayerActions {
  authenticated: (player: Player) => void
  notAuthenticated: () => void
  balanceChanged: (balance: Balance) => void
  betPlaced: (bet: Bet) => void
  betWon: (bet: Bet) => void
  betCancelled: (bet: Bet) => void
  betRejected: (betAmount: number) => void
  betUnaccepted: (bet: Bet) => void
}

type PlayerStore = PlayerState & { actions: PlayerActions }

const usePlayerStore = create<PlayerStore>()(
  devtools(
    immer((set) => ({
      currentPlayer: unknownPlayer,
      actions: {
        authenticated: (player: Player) => {
          set(
            (state) => {
              state.currentPlayer = player
            },
            undefined,
            'setCurrentPlayer'
          )
        },
        notAuthenticated: () => {
          set(
            (state) => {
              state.currentPlayer = anonymousPlayer
            },
            undefined,
            'notAuthenticated'
          )
        },
        balanceChanged: (balance: Balance) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                state.currentPlayer.balance = balance
              }
              // Update demo store
              useDemoStore.getState().actions.balanceChanged(balance)
            },
            undefined,
            'balanceChanged'
          )
        },
        betPlaced: (bet: Bet) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                const newBalance = {
                  ...state.currentPlayer.balance,
                  amount: state.currentPlayer.balance.amount - bet.betAmount
                }

                state.currentPlayer.balance = newBalance

                // Update demo store
                useDemoStore.getState().actions.balanceChanged(newBalance)
              }
            },
            undefined,
            'betPlaced'
          )
        },
        betWon: (bet: Bet) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                const allBets = useBetStore.getState().bets
                const existingBet = pipe(
                  allBets.allIds,
                  A.map((id) => allBets.byId[id]),
                  A.map(O.fromNullable),
                  A.getSomes,
                  A.findFirst((b) => b.id === bet.id),
                  O.getOrUndefined
                )

                if (existingBet?.status === betStatusMap.WON) {
                  return state
                }

                const newBalance: Balance = {
                  ...state.currentPlayer.balance,
                  amount: state.currentPlayer.balance.amount + bet.potentialPayout
                }

                state.currentPlayer.balance = newBalance

                // Update demo store
                useDemoStore.getState().actions.balanceChanged(newBalance)
              }
            },
            undefined,
            'betWon'
          )
        },
        betCancelled: (bet: Bet) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                const allBets = useBetStore.getState().bets
                const existingBet = pipe(
                  allBets.allIds,
                  A.map((id) => allBets.byId[id]),
                  A.map(O.fromNullable),
                  A.getSomes,
                  A.findFirst((b) => b.id === bet.id),
                  O.getOrUndefined
                )

                if (existingBet?.status === betStatusMap.CANCELLED) {
                  return state
                }

                const newBalance: Balance = {
                  ...state.currentPlayer.balance,
                  amount: state.currentPlayer.balance.amount + bet.betAmount
                }

                state.currentPlayer.balance = newBalance

                // Update demo store
                useDemoStore.getState().actions.balanceChanged(newBalance)
              }
            },
            undefined,
            'betCancelled'
          )
        },
        betRejected: (betAmount: number) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                const newBalance: Balance = {
                  ...state.currentPlayer.balance,
                  amount: state.currentPlayer.balance.amount + betAmount
                }

                state.currentPlayer.balance = newBalance

                // Update demo store
                useDemoStore.getState().actions.balanceChanged(newBalance)
              }
            },
            undefined,
            'betRejected'
          )
        },
        betUnaccepted: (bet: Bet) => {
          set(
            (state) => {
              if (isAuthenticatedPlayer(state.currentPlayer)) {
                const newBalance: Balance = {
                  ...state.currentPlayer.balance,
                  amount: state.currentPlayer.balance.amount + bet.betAmount
                }

                state.currentPlayer.balance = newBalance

                // Update demo store
                useDemoStore.getState().actions.balanceChanged(newBalance)
              }
            },
            undefined,
            'betRejected'
          )
        }
      }
    })),
    { name: 'PlayerStore', enabled: isDevtoolsEnabled() }
  )
)

export const useGetCurrentPlayer = () => usePlayerStore((state) => state.currentPlayer)

export const useGetCurrentPlayerId = () =>
  usePlayerStore((state) =>
    isAuthenticatedPlayer(state.currentPlayer) ? state.currentPlayer.id : undefined
  )

export const useGetCurrentPlayerSessionId = () =>
  usePlayerStore((state) =>
    isAuthenticatedPlayer(state.currentPlayer) ? state.currentPlayer.sessionId : undefined
  )

export const useGetCurrentPlayerToken = () =>
  usePlayerStore((state) => {
    if (!isAuthenticatedPlayer(state.currentPlayer)) {
      return undefined
    }

    // Check if token is valid
    const tokenData = getValidDecodedToken(state.currentPlayer.token)

    if (!tokenData) {
      state.actions.notAuthenticated()
      return undefined
    }

    return state.currentPlayer.token
  })

export const usePlayerActions = () => usePlayerStore((state) => state.actions)

export const useIsAuthenticated = () =>
  usePlayerStore((state) => isAuthenticatedPlayer(state.currentPlayer))

export const useGetAuthenticationState = () => usePlayerStore((state) => state.currentPlayer.type)

export const useIsDemoMode = () =>
  usePlayerStore(
    (state) =>
      isAuthenticatedPlayer(state.currentPlayer) &&
      state.currentPlayer.username.startsWith('_demoUser_')
  )
