import type { CSSProperties } from 'react'
import { useState, useCallback, useEffect } from 'react'
import useDeviceOrientation from 'src/hooks/useDeviceOrientation'

interface ViewportScaleOptions {
  idealViewportWidth?: number
  enabled?: boolean
  transform: string | ((scale: number) => string)
  transformOrigin?: string
}

export const useViewportScale = ({
  idealViewportWidth = 1120,
  enabled = true,
  transform,
  transformOrigin
}: ViewportScaleOptions): CSSProperties => {
  const [scaleStyle, setScaleStyle] = useState<CSSProperties>({})
  const { isMobile } = useDeviceOrientation()

  const updateScaleStyle = useCallback(() => {
    if (!enabled || isMobile) {
      setScaleStyle({})
      return
    }

    const viewportWidth = window.innerWidth
    const scaleFactor = Math.min(viewportWidth / idealViewportWidth, 1)

    const transformValue = typeof transform === 'function' ? transform(scaleFactor) : transform

    const style: CSSProperties = {
      transform: transformValue
    }

    if (transformOrigin) {
      style.transformOrigin = transformOrigin
    }

    setScaleStyle(style)
  }, [enabled, isMobile, idealViewportWidth, transform, transformOrigin])

  useEffect(() => {
    updateScaleStyle()
    window.addEventListener('resize', updateScaleStyle)
    return () => {
      window.removeEventListener('resize', updateScaleStyle)
    }
  }, [updateScaleStyle])

  return scaleStyle
}

// Preset transforms
export const transformPresets = {
  basic: (scale: number) => `scale(${scale})`,
  overlayBanners: (scale: number) => `scale(${scale}) translate(-50%, -50%)`,
  bottomPanel: (scale: number) => `scale(${scale}) translateX(-50%)`,
  rightFloatingPanel: (scale: number) => `scale(${scale}) translateY(-50%)`,
  streamMessage: (scale: number) => `scale(${scale}) translate(-50%, -50%)`
} as const
