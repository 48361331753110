import { useGetActiveTab, useGetIsSidebarOpen, useUIActions } from 'src/store/ui';
import type { MarbleGame } from 'src/types/domain/Game';
import { useGetCurrentPlayer } from 'src/store/player';
import RightSidebar from './RightSidebar';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
type Props = Readonly<{
  activeTab?: string | null;
  showStatsAndRules: boolean;
  game?: MarbleGame;
}>;
export function RightSidebarContainer({
  showStatsAndRules,
  game
}: Props) {
  const player = useGetCurrentPlayer();
  const {
    sidebarOpened
  } = useUIActions();
  const isSidebarOpen = useGetIsSidebarOpen();
  const activePanel = useGetActiveTab();
  const uiStateActions = useUIActions();
  const {
    playEffect
  } = useSoundEffect();
  const handleCloseSidebar = () => {
    sidebarOpened(false);
    playEffect('click');
  };
  return <RightSidebar activePanel={activePanel} game={game} isOpen={isSidebarOpen} onClose={handleCloseSidebar} player={player} setActivePanel={uiStateActions.tabActivated} showStatsAndRules={showStatsAndRules} data-sentry-element="RightSidebar" data-sentry-component="RightSidebarContainer" data-sentry-source-file="RightSidebarContainer.tsx" />;
}