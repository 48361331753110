import { create } from 'zustand'
import { setAutoFreeze } from 'immer'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { Bet } from 'src/types/domain/Bet'
import { AppErrorTypes } from 'src/types/domain/AppError'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

setAutoFreeze(false)

type UIStore = UIState & { actions: UIActions }

interface NoToast {
  show: false
}

interface SuccessToast {
  show: true
  message: string
  type: 'success'
}

interface ErrorToast {
  show: boolean
  message: string
  type: 'error'
}

interface WarningToast {
  show: true
  message: string
  type: 'warning'
}

type ToastState = NoToast | SuccessToast | ErrorToast | WarningToast

interface UIState {
  isAudioSettingsOpen: boolean
  isSidebarOpen: boolean
  activeTab: string
  hasInitialBalanceAnimationOccurred: boolean
  toast: ToastState
}

type OutOfRangeType = 'belowRange' | 'aboveRange'
type ForceBetValueType = 'decrease' | 'increase' | 'balance'

export interface UIActions {
  audioSettingsOpened: (isAudioSettingsOpen: boolean) => void
  audioSettingsToggled: () => void
  sidebarOpened: (isSidebarOpen: boolean) => void
  sidebarOpenToggled: () => void
  tabActivated: (activeTab: string) => void
  initialBalanceAnimationOccurred: () => void
  betUnaccepted: (bet: Bet) => void
  betRejected: (errorType: string) => void
  closeButtonClicked: () => void
  storeBalanceCheckFailed: () => void
  betValueOutOfRange: (type: OutOfRangeType) => void
  forcedBetValue: (type: ForceBetValueType) => void
  autoBettingStopped: () => void
  clearToast: () => void
}

const useUIStore = create<UIStore>()(
  devtools(
    immer((set, get) => ({
      isSidebarOpen: false,
      isAudioSettingsOpen: false,
      activeTab: 'none',
      hasInitialBalanceAnimationOccurred: false,
      toast: {
        show: false
      },
      actions: {
        audioSettingsOpened: (isAudioSettingsOpen: boolean) => {
          set({ isAudioSettingsOpen }, undefined, 'audioSettingsOpened')
        },
        audioSettingsToggled: () => {
          set(
            { isAudioSettingsOpen: !get().isAudioSettingsOpen },
            undefined,
            'audioSettingsToggled'
          )
        },
        sidebarOpened: (isSidebarOpen: boolean) => {
          set({ isSidebarOpen }, undefined, 'sidebarOpened')
        },
        sidebarOpenToggled: () => {
          set({ isSidebarOpen: !get().isSidebarOpen }, undefined, 'sidebarOpenToggled')
        },
        tabActivated: (activeTab: string) => {
          set({ activeTab }, undefined, 'tabActivated')
        },
        initialBalanceAnimationOccurred: () => {
          set(
            { hasInitialBalanceAnimationOccurred: true },
            undefined,
            'initialBalanceAnimationOccurred'
          )
        },
        storeBalanceCheckFailed: () => {
          set(
            {
              toast: {
                show: true,
                message: 'Could not place bet, insufficient balance',
                type: 'error'
              }
            },
            undefined,
            'insufficientBalance'
          )
        },
        betRejected: (errorType: string) => {
          set(
            (state) => {
              if (errorType === AppErrorTypes.NotEnoughMoneyError) {
                state.toast = {
                  show: true,
                  message: 'Could not place bet, insufficient balance',
                  type: 'error'
                }
              }

              if (errorType === AppErrorTypes.RoundClosed) {
                state.toast = {
                  show: true,
                  message: 'Could not place bet, round is closed',
                  type: 'error'
                }
              }

              if (errorType === AppErrorTypes.BetAlreadyExists) {
                state.toast = {
                  show: true,
                  message: 'Could not place bet, bet already exists',
                  type: 'error'
                }
              }

              state.toast = { show: true, message: 'Bet rejected, reason unknown', type: 'error' }
            },
            undefined,
            'betRejected'
          )
        },
        betUnaccepted: (_bet: Bet) => {
          set(
            {
              toast: {
                show: true,
                message: 'Bet could not be placed, please try again',
                type: 'error'
              }
            },
            undefined,
            'betUnaccepted'
          )
        },
        closeButtonClicked: () => {
          set({ toast: { show: false } }, undefined, 'closeButtonClicked')
        },
        betValueOutOfRange: (type: OutOfRangeType) => {
          const message =
            type === 'aboveRange'
              ? 'Amount is greater than the maximum bet amount.'
              : 'Amount is less than the minimum bet amount.'

          set({ toast: { show: true, message, type: 'error' } }, undefined, 'betValueOutOfRange')
        },
        forcedBetValue: (type: ForceBetValueType) => {
          let message
          switch (type) {
            case 'increase':
              message = 'Amount set to the maximum bet amount.'
              break
            case 'decrease':
              message = 'Amount set to the minimum bet amount.'
              break
            case 'balance':
              message = 'Amount set to the available balance.'
              break
          }

          set({ toast: { show: true, message, type: 'success' } }, undefined, 'forcedBetValue')
        },
        autoBettingStopped: () => {
          set(
            {
              toast: {
                show: true,
                message: 'Auto betting is turned off due to insufficient balance',
                type: 'success'
              }
            },
            undefined,
            'autoBettingStopped'
          )
        },
        clearToast: () => {
          set({ toast: { show: false } }, undefined, 'clearToast')
        }
      }
    })),
    { name: 'UIStore', enabled: isDevtoolsEnabled() }
  )
)

export const useUIActions = () => useUIStore((state) => state.actions)

export const useGetIsAudioSettingsOpen = () => useUIStore((state) => state.isAudioSettingsOpen)
export const useGetIsSidebarOpen = () => useUIStore((state) => state.isSidebarOpen)
export const useGetActiveTab = () => useUIStore((state) => state.activeTab)
export const useGetHasInitialBalanceAnimationOccurred = () =>
  useUIStore((state) => state.hasInitialBalanceAnimationOccurred)
export const useGetToast = () => useUIStore((state) => state.toast)
