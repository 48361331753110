import dayjs, { extend } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import { useClientSideTranslation } from 'src/services/i18n'
import { useGetCurrentLanguage } from 'src/store/game'

extend(relativeTime)
extend(duration)

interface TimeFormatterOptions {
  detailed?: boolean
  threshold?: number
}

export function useFormatTimeDifference() {
  const { t } = useClientSideTranslation(useGetCurrentLanguage())

  return (timestamp: string, options: TimeFormatterOptions = {}): string => {
    const { detailed = true, threshold = 60 } = options
    const now = dayjs()
    const then = dayjs(timestamp)
    const diffMinutes = now.diff(then, 'minute')

    if (detailed && diffMinutes < threshold) {
      const diff = now.diff(then, 'second')
      const minutes = Math.floor(diff / 60)
      const seconds = diff % 60

      if (minutes === 0) {
        return `${t('{{seconds}} sec ago', { seconds })}`
      }
      return `${t('{{minutes}} min {{seconds}} sec ago', { minutes, seconds })}`
    }
    return then.fromNow()
  }
}
