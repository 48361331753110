import { useEffect, useState } from 'react';
import { useFullscreenAvailable } from 'src/hooks/useFullscreenAvailable';
import { FullScreenButton } from './FullScreenButton';
type Props = Readonly<{
  className?: string;
}>;
export function FullScreenButtonContainer({
  className
}: Props) {
  const isFullscreenAvailable = useFullscreenAvailable();
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);
  const toggleFullscreen = () => {
    const element = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>;
    };
    if (!isFullscreen) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Necessary for browser API compatibility
      if (element.requestFullscreen) {
        void element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        void element.webkitRequestFullscreen();
      }
    } else {
      // eslint-disable-next-line no-lonely-if, @typescript-eslint/no-unnecessary-condition -- Necessary for browser API compatibility
      if (document.exitFullscreen) {
        void document.exitFullscreen();
      }
    }
  };

  // Render the button only if fullscreen is allowed
  if (!isFullscreenAvailable) return null;
  return <FullScreenButton className={className} onClick={toggleFullscreen} data-sentry-element="FullScreenButton" data-sentry-component="FullScreenButtonContainer" data-sentry-source-file="FullScreenButtonContainer.tsx" />;
}