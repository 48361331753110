import { useEffect, useState } from 'react';
import { Console, Array as A, Effect, Option as O, pipe } from 'effect';
import { getOrdinalSuffix } from 'src/utils/getOrdinalSuffix';
import { formatDate } from 'src/utils/formatDate';
import type { AuthenticatedPlayer } from 'src/types/domain/Player';
import FlagCheckeredIcon from 'src/app/assets/icons/ico-flag-checkered.svg';
import AngleUpIcon from 'src/app/assets/icons/ico-angle-up.svg';
import type { Bet } from 'src/types/domain/Bet';
import { getCurrencySymbol } from 'src/utils/getCurrencySymbol';
import { BetClassNameMap, BetLabelMap, getVisualPotentialPayout } from 'src/utils/betUtils';
import type { MarbleGame } from 'src/types/domain/Game';
import type { Marble } from 'src/types/domain/Marble';
import type { Race } from 'src/types/domain/Race';
import { isRaceSettledEvent, type RaceEvent, type RaceSettledEvent } from 'src/types/domain/RaceEvent';
import { displayAmount } from 'src/utils/displayAmount';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import { getRacesByIds } from 'src/services/supabase/queries';
import { getRaceRoundId } from 'src/utils/getRaceRoundId';
import { useFormatTimeDifference } from 'src/utils/formatTimeDifference';
import { MarbleItem } from '../../MarbleItem';
type Props = Readonly<{
  game: MarbleGame;
  bets: Bet[];
  player: AuthenticatedPlayer;
  raceTrackName?: string;
}>;
const getRaceForBet = (races: Race[], bet: Bet) => races.find(race => race.uuid === bet.raceId);
export default function BetHistoryPane({
  bets,
  game,
  raceTrackName
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [expandedBetId, setExpandedBetId] = useState<string | null>(null);
  const [copiedStates, setCopiedStates] = useState<Record<string, boolean>>({});
  const [historicalBets, setHistoricalBets] = useState<Bet[]>([]);
  const [races, setRaces] = useState<Race[]>([]);
  const [currentBatch, setCurrentBatch] = useState<number>(0);
  const [hasMoreBets, setHasMoreBets] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formatTimeDifference = useFormatTimeDifference();
  const latestRaceId = game.races[0].uuid;
  const fetchRaces = async (betBatch: Bet[]) => {
    await pipe(getRacesByIds({
      ids: betBatch.map(bet => bet.raceId).filter(id => Boolean(id)),
      order: 'desc',
      orderBy: 'created_at'
    }), Effect.tapError(error => Console.error('Error fetching races:', error)), Effect.tap(results => {
      setRaces(prevRaces => {
        const newRaces = results.filter(newRace => !prevRaces.some(prevRace => prevRace.uuid === newRace.uuid));
        return [...prevRaces, ...newRaces];
      });
    }), Effect.runPromise);
  };
  useEffect(() => {
    const fetchHistoricalBets = async () => {
      const sortedBets = [...bets].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      const start = currentBatch * 10;
      const end = start + 10;
      const betBatch = sortedBets.slice(start, end);
      if (betBatch.length < 10) {
        setHasMoreBets(false);
      }
      const filteredBets = betBatch.filter(bet => bet.status !== 'pending' || bet.raceId !== latestRaceId);
      setHistoricalBets(prevBets => {
        const newBets = filteredBets.filter(newBet => !prevBets.some(prevBet => prevBet.id === newBet.id));
        return [...prevBets, ...newBets];
      });
      await fetchRaces(filteredBets);
      setIsLoading(false);
    };
    void fetchHistoricalBets();
  }, [bets, latestRaceId, currentBatch]);
  const loadMoreBets = () => {
    setIsLoading(true);
    setCurrentBatch(prevBatch => prevBatch + 1);
  };
  const isRaceEnded = (race?: Race) => {
    return race?.raceEvents.some((event: RaceEvent) => event.eventType === 'race_settled');
  };
  const handleEntryClick = (betId: string) => {
    setExpandedBetId(expandedBetId === betId ? null : betId);
  };
  const handleCopy = async (value: string, key: string) => {
    try {
      // First try the modern Clipboard API
      try {
        await navigator.clipboard.writeText(value);
        setCopiedStates(prev => ({
          ...prev,
          [key]: true
        }));
      } catch (clipboardError) {
        // Fallback to execCommand if Clipboard API fails
        const textarea = document.createElement('textarea');
        textarea.value = value;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        textarea.style.pointerEvents = 'none';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          const successful = document.execCommand('copy');
          if (!successful) throw new Error('Copy command was unsuccessful');
          setCopiedStates(prev => ({
            ...prev,
            [key]: true
          }));
        } finally {
          document.body.removeChild(textarea);
        }
      }

      // Set timeout to reset the "Copied!" state
      setTimeout(() => {
        setCopiedStates(prev => ({
          ...prev,
          [key]: false
        }));
      }, 2000);
    } catch (err) {
      setCopiedStates(prev => ({
        ...prev,
        [key]: false
      }));
    }
  };
  const renderBetMarbles = (bet: Bet, raceSettled: RaceSettledEvent | undefined, isRaceEnded: boolean) => {
    const betInExactOrder = ['StraightForecast', 'Tricast'].includes(bet.betDetails.type);
    const selectedMarbles = bet.betDetails.selectedItems.map(marbleId => game.marbles.find((m: Marble) => m.uuid === marbleId)).filter((m: Marble | undefined) => m !== undefined);
    if (selectedMarbles.length === 0) return null;
    if (bet.betDetails.type === 'PickWinner') {
      return selectedMarbles.map(marble => {
        const {
          position,
          positionSuffix,
          isWinningMarble
        } = pipe(O.fromNullable(raceSettled), O.map(result => result.payload.results), O.flatMap(results => pipe(results, A.findFirst(result => result.marbleId === marble.uuid), O.map(result => ({
          position: result.position,
          positionSuffix: getOrdinalSuffix(Number(result.position)),
          isWinningMarble: isRaceEnded && result.position === 1
        })))), O.getOrElse(() => ({
          position: '',
          positionSuffix: '',
          isWinningMarble: false
        })));
        return <MarbleItem className={`${isWinningMarble ? 'winning-marble' : ''} marble-${marble.color}`} key={marble.number} marble={marble} tooltip={position ? `${marble.color} -> ${position}${positionSuffix}` : marble.color} />;
      });
    }
    return <div className="choose-marbles-order show-single-position-compact" data-sentry-component="renderBetMarbles" data-sentry-source-file="BetHistoryPane.tsx">
        {selectedMarbles.map(marble => {
        const position = bet.betDetails.selectedItems.indexOf(marble.uuid) + 1;
        const positionSuffix = getOrdinalSuffix(Number(position));
        return <div className="entry-order entry-order-marble-spin" key={`${bet.betDetails.type}${marble.uuid}`}>
              <div className="entry-marble">
                <MarbleItem className={`marble-${marble.color}`} key={marble.uuid} marble={marble} tooltip={isRaceEnded ? `${marble.color} -> ${position}${positionSuffix}` : undefined} />
              </div>
              {betInExactOrder ? <div className="entry-selector">
                  <div className={`select ${getOrdinalSuffix(position)} selected`}>
                    {position}
                    {getOrdinalSuffix(position)}
                  </div>
                </div> : <div className="entry-selector">
                  <div className="select">{t('Any')}</div>
                </div>}
            </div>;
      })}
      </div>;
  };
  return <div className="race-history-sidebar" data-sentry-component="BetHistoryPane" data-sentry-source-file="BetHistoryPane.tsx">
      <h3>{raceTrackName}</h3>
      <div className="std-table three-rows-multiple-columns">
        <div className="std-table__inner">
          <div className="std-table__inner-body no-max-height">
            {historicalBets.length > 0 ? historicalBets.map((bet: Bet) => {
            const race = getRaceForBet(races, bet);
            const raceSettledEvent = pipe(O.fromNullable(race), O.flatMap(race => A.findFirst(race.raceEvents, isRaceSettledEvent)), O.getOrUndefined);
            const raceEnded = isRaceEnded(race) ?? false;
            const isExpanded = expandedBetId === bet.id;
            return <div className={`entry entry-expandable ${BetClassNameMap[bet.status]} ${isExpanded ? 'expanded' : ''}`} key={bet.id} onClick={() => {
              handleEntryClick(bet.id);
            }}>
                    <div className="expand-icon">
                      <AngleUpIcon />
                    </div>
                    <div className="top-bar">
                      <div className="top-bar_entry-row">
                        <span className="race-id">
                          <FlagCheckeredIcon />{' '}
                          {race && getRaceRoundId(race.round, game.track.shortName)}
                        </span>
                        <span className="time-ago">{formatTimeDifference(bet.createdAt)}</span>
                      </div>
                      <div className="top-bar_entry-row">
                        <div className="amount">
                          <span className="amount">
                            {getCurrencySymbol(bet.currency)}
                            {displayAmount(bet.betAmount.toString())}
                          </span>
                        </div>
                        <div className="label">{t('Bet Amount')}</div>
                      </div>
                      {bet.status === 'won' ? <div className="top-bar_entry-row">
                          <div className="amount">
                            <span className="amount">
                              {getCurrencySymbol(bet.currency)}
                              {getVisualPotentialPayout(bet, bet.status)}
                            </span>
                          </div>
                          <div className="label">{t('Won Amount')}</div>
                        </div> : <div className="amount">{t('Lost')}</div>}
                    </div>
                    <div className={`expanded-info-bar ${isExpanded ? 'visible' : ''}`}>
                      <div className="info-entry">
                        <span className="label">{t('Race Id')}:</span>
                        <span className="value">#{bet.raceId}</span>
                        <button className="default-btn" onClick={e => {
                    e.stopPropagation();
                    handleCopy(bet.raceId, `race-${bet.id}`);
                  }}>
                          <span>{copiedStates[`race-${bet.id}`] ? 'Copied!' : 'Copy'}</span>
                        </button>
                      </div>
                      {race && <div className="info-entry">
                          <span className="label">Race round:</span>
                          <span className="value">
                            {getRaceRoundId(race.round, game.track.shortName)}
                          </span>
                          <button className="default-btn" onClick={e => {
                    e.stopPropagation();
                    handleCopy(getRaceRoundId(race.round, game.track.shortName), `race-round-${getRaceRoundId(race.round, game.track.shortName)}`);
                  }}>
                            <span>
                              {copiedStates[`race-round-${getRaceRoundId(race.round, game.track.shortName)}`] ? 'Copied!' : 'Copy'}
                            </span>
                          </button>
                        </div>}
                      <div className="info-entry">
                        <span className="label">{t('Bet Id')}:</span>
                        <span className="value">#{bet.id}</span>
                        <button className="default-btn" onClick={e => {
                    e.stopPropagation();
                    handleCopy(bet.id, `bet-${bet.id}`);
                  }}>
                          <span>{copiedStates[`bet-${bet.id}`] ? 'Copied!' : 'Copy'}</span>
                        </button>
                      </div>

                      <div className="info-entry">
                        <span className="label">{t('Bet Time')}:</span>
                        <span className="value">{formatDate(bet.createdAt)}</span>
                        <button className="default-btn" onClick={e => {
                    e.stopPropagation();
                    handleCopy(bet.createdAt, `time-${bet.id}`);
                  }}>
                          <span>{copiedStates[`time-${bet.id}`] ? 'Copied!' : 'Copy'}</span>
                        </button>
                      </div>
                      <div className="info-entry">
                        <span className="label">{t('Bet Amount')}:</span>
                        <span className="value">
                          {getCurrencySymbol(bet.currency)}
                          {displayAmount(bet.betAmount.toString())}
                        </span>
                      </div>
                      <div className="info-entry">
                        <span className="label">{t('Won Amount')}:</span>
                        <span className="value">
                          {getCurrencySymbol(bet.currency)}
                          {getVisualPotentialPayout(bet, bet.status)}
                        </span>
                      </div>
                      <div className="info-entry">
                        <span className="label">{t('Outcome')}:</span>
                        <span className="value">{bet.status}</span>
                      </div>
                    </div>
                    <div className={`bottom-bar ${bet.betDetails.type}`}>
                      <span className="label">{BetLabelMap[bet.betDetails.type]}</span>
                      <div className="marbles">
                        {renderBetMarbles(bet, raceSettledEvent, raceEnded)}
                      </div>
                    </div>
                  </div>;
          }) : <div className="entry entry--empty">
                <span>{t('No bet history found')}</span>
              </div>}

            {hasMoreBets && <button className="default-btn h-6 block mt-2" onClick={loadMoreBets} disabled={isLoading}>
                <span>{isLoading ? t('Loading...') : t('Load More')}</span>
              </button>}
          </div>
        </div>
      </div>
    </div>;
}