import { useRef } from 'react'

export function use3dHoverEffect() {
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove('hovered')
    event.currentTarget.style.removeProperty('--rotateX')
    event.currentTarget.style.removeProperty('--rotateY')
    event.currentTarget.style.removeProperty('--translateZ')

    const topOverlay = event.currentTarget.querySelector('.entry-bottom')
    if (topOverlay instanceof HTMLElement) {
      topOverlay.style.removeProperty('--rotateX')
      topOverlay.style.removeProperty('--rotateY')
      topOverlay.style.removeProperty('--translateZ')
    }

    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current)
    }
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.classList.add('hovered')
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const card = event.currentTarget
    const cardRect = card.getBoundingClientRect()
    const cardCenterX = cardRect.left + cardRect.width / 2
    const cardCenterY = cardRect.top + cardRect.height / 2
    const mouseX = event.clientX - cardCenterX
    const mouseY = event.clientY - cardCenterY
    const rotateX = (mouseY / (cardRect.height / 2)) * 5
    const rotateY = (mouseX / (cardRect.width / 2)) * 5

    card.style.setProperty('--rotateX', `${-rotateX}deg`)
    card.style.setProperty('--rotateY', `${rotateY}deg`)
    card.style.setProperty('--translateZ', `${Math.abs(rotateX) + Math.abs(rotateY)}px`)

    const topOverlay = card.querySelector('.entry-bottom')
    if (topOverlay instanceof HTMLElement) {
      topOverlay.style.setProperty('--rotateX', `${-rotateX * 1.5}deg`)
      topOverlay.style.setProperty('--rotateY', `${rotateY * 1.5}deg`)
      topOverlay.style.setProperty(
        '--translateZ',
        `${(Math.abs(rotateX) + Math.abs(rotateY)) * 2.5}px`
      )
    }
  }

  return {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  }
}
