'use client';

import BroadcastControls from 'src/components/BroadcastControls';
import { transformPresets, useViewportScale } from 'src/hooks/useViewportScale';
import { BalanceContainer } from './Balance/BalanceContainer';
import { ToolbarInfoContainer } from './ToolbarInfo/ToolbarInfoContainer';
import { Logo } from './Logo/Logo';
type Props = Readonly<{
  showLogo?: boolean;
  onToggleSidebar: () => void;
  isSidebarOpen: boolean;
  goToBack: () => void;
}>;
export function GameTopToolbar({
  showLogo = false,
  onToggleSidebar,
  isSidebarOpen,
  goToBack
}: Props) {
  const scaleStyle = useViewportScale({
    transform: transformPresets.basic
  });
  return <div className="game-top-toolbar" style={scaleStyle} data-sentry-component="GameTopToolbar" data-sentry-source-file="GameTopToolbar.tsx">
      <div className="game-top-toolbar__inner">
        {showLogo ? <Logo /> : null}
        <ToolbarInfoContainer goToBack={goToBack} data-sentry-element="ToolbarInfoContainer" data-sentry-source-file="GameTopToolbar.tsx" />
        <div className="game-top-toolbar__right">
          <BroadcastControls data-sentry-element="BroadcastControls" data-sentry-source-file="GameTopToolbar.tsx" />

          <BalanceContainer isSidebarOpen={isSidebarOpen} onToggleSidebar={onToggleSidebar} data-sentry-element="BalanceContainer" data-sentry-source-file="GameTopToolbar.tsx" />
        </div>
      </div>
    </div>;
}