'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { ShowGamesContainer } from 'src/components/ShowGames/index';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { GameTopToolbarContainer } from 'src/components/GameTopToolbar';
import { RightSidebarContainer } from 'src/components/RightSidebar';
import { AnonymousPlayerView } from 'src/components/AnonymousPlayerView';
import { unknownGame } from 'src/types/domain/Game';
import { useLobbyListeners } from 'src/hooks/useLobbyListeners';
import { useGetAuthenticationState } from 'src/store/player';
import { useOneTimeLogin } from 'src/hooks/useOneTimeLogin';
import { useGames } from 'src/hooks/useGames';
import { useGameActions, useGetCurrentGameId, useGetCurrentLanguage } from 'src/store/game';
import { useGetIsMasterMuted, useGetIsMusicMuted } from 'src/store/sound';
import { useClientSideTranslation } from 'src/services/i18n/client';
import useDeviceOrientation from 'src/hooks/useDeviceOrientation';
import { transformPresets, useViewportScale } from 'src/hooks/useViewportScale';
import { OrientationMessage } from 'src/components/OrientationMessage';
import FlashIcon from './assets/icons/ico-flash.svg';
type Props = Readonly<{
  searchParams?: Record<string, string | undefined>;
}>;
export default function App({
  searchParams
}: Props) {
  useOneTimeLogin(searchParams?.otl);
  const authenticationState = useGetAuthenticationState();
  const scaleStyle = useViewportScale({
    transform: transformPresets.basic
  });
  const gameActions = useGameActions();
  const currentGameId = useGetCurrentGameId();
  const {
    fetchCurrentGames,
    hasGames,
    allGamesFetched
  } = useGames();
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const {
    isLandscape,
    isMobile
  } = useDeviceOrientation();
  const isMasterMuted = useGetIsMasterMuted();
  const isMusicMuted = useGetIsMusicMuted();
  useLobbyListeners();
  useEffect(() => {
    // Only start music if neither master nor music is muted
    if (!isMasterMuted && !isMusicMuted) {
      // playMusic()
    }
    return () => {
      // stopMusic()
    };
  }, [isMasterMuted, isMusicMuted]);

  // Reset potential current game
  useEffect(() => {
    if (currentGameId) {
      gameActions.setCurrentGame(unknownGame);
    }
  }, [currentGameId, gameActions]);
  useEffect(() => {
    void fetchCurrentGames();
  }, [fetchCurrentGames]);
  if (authenticationState === 'AnonymousPlayer') {
    return <AnonymousPlayerView />;
  }
  if (!hasGames || authenticationState === 'UnknownPlayer') {
    return <LoadingIndicator />;
  }
  if (isMobile && isLandscape) {
    return <OrientationMessage />;
  }
  return <div className="marbles-game_outer" data-sentry-component="App" data-sentry-source-file="page.tsx">
      <main className="marbles-game">
        <RightSidebarContainer showStatsAndRules={false} data-sentry-element="RightSidebarContainer" data-sentry-source-file="page.tsx" />

        <GameTopToolbarContainer data-sentry-element="GameTopToolbarContainer" data-sentry-source-file="page.tsx" />
        <div className="track-sidebar__outer" style={scaleStyle}>
          <div className="track-sidebar">
            <div className="logo">
              <Image alt="Marbles" draggable="false" height={40} src="/marbles_symbol-white.webp" width={40} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
            </div>
            <nav>
              <button className="active" type="button">
                <span className="inner">
                  <span className="icon">
                    <FlashIcon data-sentry-element="FlashIcon" data-sentry-source-file="page.tsx" />
                  </span>
                </span>
              </button>
            </nav>
          </div>
        </div>
        <div className="marbles-game__container-inner" style={scaleStyle}>
          <div className="marbles-game_scroll-container">
            {/* <GameHeroContainer /> */}

            <div className="track_inner-content">
              {Boolean(allGamesFetched) && <ShowGamesContainer title={t('All Live Tracks')} />}
            </div>
          </div>
        </div>
      </main>
    </div>;
}