import { create } from 'zustand'
import { setAutoFreeze } from 'immer'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { SoundVolumes, SoundMuteState } from 'src/types/domain/Sounds'
import { VOLUME_TYPES, MUTE_TYPES } from 'src/types/domain/Sounds'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

setAutoFreeze(false)

interface SoundState {
  isPlayingMusic: boolean
  volumes: SoundVolumes
  muted: SoundMuteState
  currentSongIndex: number
  currentSoundEffects: { type: EffectType; id: string }[]
  currentSoundNotifications: { type: NotificationType; id: string }[]
}

// Sound action interfaces
// Here we use present tense for these actions as we are describing the action
// and not the event that happened. Ex. muteMusic instead of buttonClicked or timerHit.
export interface SoundActions {
  // Master
  changeMasterVolume: (volume: number) => void
  muteMaster: () => void
  unmuteMaster: () => void
  // Race
  changeRaceVolume: (volume: number) => void
  muteRace: () => void
  unmuteRace: () => void
  // Music
  changeMusicVolume: (volume: number) => void
  muteMusic: () => void
  unmuteMusic: () => void
  playMusic: () => void
  stopMusic: () => void
  musicSongChanged: (song: number) => void
  // Effects
  changeEffectsVolume: (volume: number) => void
  muteEffects: () => void
  unmuteEffects: () => void
  playEffect: (effect: EffectType) => void
  clearEffect: (effectId: string) => void
  // Notifications
  playNotification: (notification: NotificationType) => void
  clearNotification: (notificationId: string) => void
}

type SoundStore = SoundState & {
  actions: SoundActions
}

export type EffectType =
  | 'panelReveal'
  | 'panelRevealReverse'
  | 'cancel'
  | 'click'
  | 'chip'
  | 'switch'
  | 'betPlaced'
  | 'marbleSelected'
  | 'marbleSelectedReverse'

export type NotificationType =
  | 'notification'
  | 'notificationRaceScheduled'
  | 'notificationRaceReady'
  | 'notificationRaceStarting'
  | 'notificationRaceStarted'
  | 'notificationRaceStartedAlt'
  | 'notificationRaceEnded'
  | 'notificationRaceSettled'
  | 'notificationRaceSettledWon1'
  | 'notificationRaceSettledLost1'
  | 'notificationRaceSettledLost2'

const initialState: SoundState = {
  currentSoundEffects: [],
  currentSoundNotifications: [],
  isPlayingMusic: false,
  currentSongIndex: 0,
  volumes: {
    [VOLUME_TYPES.MASTER]: 0.8,
    [VOLUME_TYPES.RACE_SOUNDS]: 1.0,
    [VOLUME_TYPES.SOUND_EFFECTS]: 0.5,
    [VOLUME_TYPES.MUSIC]: 0.5
  },
  muted: {
    [MUTE_TYPES.MASTER]: true,
    [MUTE_TYPES.MUSIC]: false,
    [MUTE_TYPES.RACE_SOUNDS]: false,
    [MUTE_TYPES.SOUND_EFFECTS]: false
  }
}

const useSoundStore = create<SoundStore>()(
  devtools(
    immer((set, _) => ({
      ...initialState,
      actions: {
        changeMasterVolume: (volume: number) => {
          set(
            (state) => {
              state.volumes.master = volume
            },
            undefined,
            'changeMasterVolume'
          )
        },
        muteMaster: () => {
          set(
            (state) => {
              state.muted.master = true
            },
            undefined,
            'muteMaster'
          )
        },
        unmuteMaster: () => {
          set(
            (state) => {
              state.muted.master = false
            },
            undefined,
            'unmuteMaster'
          )
        },
        changeRaceVolume: (volume: number) => {
          set(
            (state) => {
              state.volumes.race = volume
            },
            undefined,
            'changeRaceVolume'
          )
        },
        muteRace: () => {
          set(
            (state) => {
              state.muted.race = true
            },
            undefined,
            'muteRace'
          )
        },
        unmuteRace: () => {
          set(
            (state) => {
              state.muted.race = false
            },
            undefined,
            'unmuteRace'
          )
        },
        changeEffectsVolume: (volume: number) => {
          set(
            (state) => {
              state.volumes.effects = volume
            },
            undefined,
            'changeEffectsVolume'
          )
        },
        muteEffects: () => {
          set(
            (state) => {
              state.muted.effects = true
            },
            undefined,
            'muteEffects'
          )
        },
        unmuteEffects: () => {
          set(
            (state) => {
              state.muted.effects = false
            },
            undefined,
            'unmuteEffects'
          )
        },
        musicSongChanged: (songIndex: number) => {
          set(
            (state) => {
              state.currentSongIndex = songIndex
            },
            undefined,
            'musicSongChanged'
          )
        },
        changeMusicVolume: (volume: number) => {
          set(
            (state) => {
              state.volumes.music = volume
            },
            undefined,
            'changeMusicVolume'
          )
        },
        muteMusic: () => {
          set(
            (state) => {
              state.muted.music = true
            },
            undefined,
            'muteMusic'
          )
        },
        unmuteMusic: () => {
          set(
            (state) => {
              state.muted.music = false
            },
            undefined,
            'unmuteMusic'
          )
        },
        playMusic: () => {
          set(
            (state) => {
              state.isPlayingMusic = true
            },
            undefined,
            'playMusic'
          )
        },
        stopMusic: () => {
          set(
            (state) => {
              state.isPlayingMusic = false
            },
            undefined,
            'stopMusic'
          )
        },
        playEffect: (effect: EffectType) => {
          set(
            (state) => {
              state.currentSoundEffects.push({ type: effect, id: Date.now().toString() })
            },
            undefined,
            { type: 'playEffect', effect } as any
          )
        },
        clearEffect: (effectId: string) => {
          set(
            (state) => {
              state.currentSoundEffects = state.currentSoundEffects.filter(
                (effect) => effect.id !== effectId
              )
            },
            undefined,
            { type: 'clearEffect', effectId } as any
          )
        },
        playNotification: (notification: NotificationType) => {
          set(
            (state) => {
              state.currentSoundNotifications.push({
                type: notification,
                id: Date.now().toString()
              })
            },
            undefined,
            'playNotification'
          )
        },
        clearNotification: (notificationId: string) => {
          set(
            (state) => {
              state.currentSoundNotifications = state.currentSoundNotifications.filter(
                (notification) => notification.id !== notificationId
              )
            },
            undefined,
            'clearNotification'
          )
        }
      }
    })),
    { name: 'SoundStore', enabled: isDevtoolsEnabled() }
  )
)

// Typed selectors
export const useSoundActions = () => useSoundStore((state) => state.actions)

// MASTER
export const useGetMasterVolume = () => useSoundStore((state) => state.volumes[VOLUME_TYPES.MASTER])
export const useGetIsMasterMuted = () => useSoundStore((state) => state.muted[MUTE_TYPES.MASTER])

// RACE SOUNDS
export const useGetRaceSoundsVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.RACE_SOUNDS])
export const useGetIsRaceSoundsMuted = () =>
  useSoundStore((state) => state.muted[MUTE_TYPES.RACE_SOUNDS])

// MUSIC
export const useGetMusicVolume = () => useSoundStore((state) => state.volumes[VOLUME_TYPES.MUSIC])
export const useGetIsMusicMuted = () => useSoundStore((state) => state.muted[MUTE_TYPES.MUSIC])
export const useGetCurrentSongIndex = () => useSoundStore((state) => state.currentSongIndex)
export const useGetIsPlayingMusic = () => useSoundStore((state) => state.isPlayingMusic)

// SOUND EFFECTS
export const useGetCurrentEffects = () => useSoundStore((state) => state.currentSoundEffects)
export const useGetEffectsVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.SOUND_EFFECTS])
export const useGetIsEffectsMuted = () =>
  useSoundStore((state) => state.muted[MUTE_TYPES.SOUND_EFFECTS])

// NOTIFICATIONS
export const useGetCurrentNotifications = () =>
  useSoundStore((state) => state.currentSoundNotifications)
// For now use the same volume as sound effects
export const useGetNotificationsVolume = () =>
  useSoundStore((state) => state.volumes[VOLUME_TYPES.SOUND_EFFECTS])
export const useGetIsNotificationsMuted = () =>
  useSoundStore((state) => state.muted[MUTE_TYPES.SOUND_EFFECTS])

// Optional: Add a selector to get all sound settings at once
export const useGetAllSoundSettings = () =>
  useSoundStore((state) => ({
    volumes: state.volumes,
    muted: state.muted
  }))
