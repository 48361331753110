import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/reset.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/marbles.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/marbles-avatars.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/game-top-toolbar.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/marbles-right-sidebar.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/marbles-cards.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/assets/css/marbles-banners.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/game-widget/src/components/Datadog/InitDatadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SoundEffects"] */ "/vercel/path0/apps/game-widget/src/components/Sound/Effects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Music"] */ "/vercel/path0/apps/game-widget/src/components/Sound/Music.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SoundNotifications"] */ "/vercel/path0/apps/game-widget/src/components/Sound/Notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@14.2.4_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-do_o5gh4nklqj5tlzfvkfr6qnnice/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-formula\",\"src\":[{\"path\":\"assets/fonts/formula1-bold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"assets/fonts/formula1-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"formulaBold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
