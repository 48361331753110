import { useState, useEffect, useCallback } from 'react'
import { healthCheck } from 'src/actions/healthCheck'

export interface ConnectionStatus {
  isOnline: boolean
  latency: number
  connectionQuality: 'good' | 'warning' | 'poor'
}

const INTERVAL_MS = 15000 // Interval between latency checks

export const useConnectionStatus = () => {
  const [status, setStatus] = useState<ConnectionStatus>({
    isOnline: navigator.onLine,
    latency: 0,
    connectionQuality: 'good'
  })

  const getConnectionQuality = (latency: number): ConnectionStatus['connectionQuality'] => {
    if (latency < 250) return 'good'
    if (latency < 750) return 'warning'
    return 'poor'
  }

  const checkLatency = useCallback(async () => {
    try {
      const start = performance.now()
      await healthCheck()
      const latency = performance.now() - start

      setStatus((prev) => ({
        ...prev,
        latency,
        connectionQuality: getConnectionQuality(latency)
      }))
    } catch (error) {
      console.error('Connection check failed:', error)
      setStatus((prev) => ({
        ...prev,
        connectionQuality: 'poor'
      }))
    }
  }, [])

  const handleOnlineStatus = useCallback(() => {
    setStatus((prev) => ({
      ...prev,
      isOnline: navigator.onLine
    }))
    if (navigator.onLine) {
      void checkLatency()
    }
  }, [checkLatency])

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOnlineStatus)

    void checkLatency()

    const interval = setInterval(() => {
      void checkLatency()
    }, INTERVAL_MS)

    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOnlineStatus)
      clearInterval(interval)
    }
  }, [handleOnlineStatus, checkLatency])

  return status
}
