// Base constants that can be used to ensure consistency
export const VOLUME_TYPES = {
  MASTER: 'master',
  RACE_SOUNDS: 'race',
  SOUND_EFFECTS: 'effects',
  MUSIC: 'music'
} as const

export const MUTE_TYPES = {
  MASTER: 'master',
  MUSIC: 'music',
  RACE_SOUNDS: 'race',
  SOUND_EFFECTS: 'effects'
} as const

// Derive types from constants for better maintainability
export type VolumeType = (typeof VOLUME_TYPES)[keyof typeof VOLUME_TYPES]
export type MuteType = (typeof MUTE_TYPES)[keyof typeof MUTE_TYPES]

// Derive action types from volume types for consistency
export type VolumeActionType = `change${Capitalize<Exclude<VolumeType, 'master'>>}Volume`

// Settings interfaces with stronger typing
export type SoundVolumes = Record<VolumeType, number>

export type SoundMuteState = Record<MuteType, boolean>

export interface SoundSettings {
  volumes: SoundVolumes
  muted: SoundMuteState
}

// Hook return type
// export interface SoundControls {
//   state: {
//     volumes: SoundVolumes
//     muted: SoundMuteState
//   }
//   controls: {
//     setVolume: (type: VolumeType, volume: number) => void
//     toggleMute: (type: MuteType) => void
//   }
// }

// Mapping object type with type guard
export const volumeChangeActions: Record<Exclude<VolumeType, 'master'>, VolumeActionType> = {
  [VOLUME_TYPES.RACE_SOUNDS]: 'changeRaceVolume',
  [VOLUME_TYPES.SOUND_EFFECTS]: 'changeEffectsVolume',
  [VOLUME_TYPES.MUSIC]: 'changeMusicVolume'
} as const

// Type guard functions
export const isVolumeType = (value: string): value is VolumeType => {
  return Object.values(VOLUME_TYPES).includes(value as VolumeType)
}

export const isMuteType = (value: string): value is MuteType => {
  return Object.values(MUTE_TYPES).includes(value as MuteType)
}
