import type { Hero } from 'src/types/infra/supabase';
import MarblesSymbolWhite from 'src/app/assets/icons/ico-marbles-symbol.svg';
import { LoadingMessages } from './LoadingMessages';
type Props = Readonly<{
  hero?: Hero;
}>;
export default function LoadingIndicator({
  hero
}: Props) {
  return <div className="race-loading-indicator-outer" data-sentry-component="LoadingIndicator" data-sentry-source-file="LoadingIndicator.tsx">
      <div className="race-loading-indicator">
        <div className="race-loading-indicator__inner">
          <MarblesSymbolWhite data-sentry-element="MarblesSymbolWhite" data-sentry-source-file="LoadingIndicator.tsx" />
          <div className="text">
            <LoadingMessages data-sentry-element="LoadingMessages" data-sentry-source-file="LoadingIndicator.tsx" />
          </div>
          <div className="progress-bar-outer">
            <span className="progress-bar" />
          </div>
        </div>
      </div>
      {hero ? <div className="loading-indicator__background">
          {hero.image ? <video autoPlay loop muted playsInline poster={`/tracks-backgrounds/${hero.image}.webp`}>
              <source src={`/tracks-backgrounds/${hero.image}-video.mp4`} type="video/mp4" />
              <source src={`/tracks-backgrounds/${hero.image}-video.webm`} type="video/webm" />
            </video> : null}
        </div> : <div className="loading-indicator__background">
          <video autoPlay loop muted playsInline poster="/marbles-racetrack-placeholder.webp">
            <source src="/marbles-cinematic-trailer.mp4" type="video/mp4" />
            <source src="/marbles-cinematic-trailer.webm" type="video/webm" />
          </video>
        </div>}
    </div>;
}