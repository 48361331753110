import { useCallback } from 'react'
import { type EffectType, useSoundActions } from 'src/store/sound'

export const useSoundEffect = () => {
  const soundStoreActions = useSoundActions()
  const playEffect = useCallback(
    (soundId: string) => {
      soundStoreActions.playEffect(soundId as EffectType)
    },
    [soundStoreActions]
  )

  return {
    playEffect
  }
}
