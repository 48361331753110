import { useEffect, useState } from 'react'

export function useInitialUserInteraction(onInteraction: () => void) {
  const [madeInitialUserInteraction, setMadeInitialUserInteraction] = useState(false)

  useEffect(() => {
    const handler = () => {
      if (!madeInitialUserInteraction) {
        setMadeInitialUserInteraction(true)
        onInteraction()
      }
    }

    // Add event listener for click, touch, and keydown events
    document.addEventListener('click', handler)
    document.addEventListener('touchstart', handler)
    document.addEventListener('keydown', handler)

    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener('click', handler)
      document.removeEventListener('touchstart', handler)
      document.removeEventListener('keydown', handler)
    }
  }, [madeInitialUserInteraction, onInteraction])
}
