import { useClientSideTranslation, getI18nSettings } from 'src/services/i18n';
import { useGetCurrentPlayer } from 'src/store/player';
import AngleLeftIcon from 'src/app/assets/icons/ico-angle-left.svg';
import CogIcon from 'src/app/assets/icons/ico-cog.svg';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import { useGameActions, useGetCurrentLanguage } from 'src/store/game';
export function SettingsPanel({
  onBackClick
}: {
  onBackClick: () => void;
}) {
  const currentLanguage = useGetCurrentLanguage();
  const {
    t
  } = useClientSideTranslation(currentLanguage);
  const player = useGetCurrentPlayer();
  const playerActions = useGameActions();
  return <div className="settings-panel" data-sentry-component="SettingsPanel" data-sentry-source-file="SettingsPanel.tsx">
      <div className="panel-header">
        <button className="back" onClick={onBackClick} type="button">
          <span className="icon">
            <AngleLeftIcon data-sentry-element="AngleLeftIcon" data-sentry-source-file="SettingsPanel.tsx" />
          </span>
        </button>
        <CogIcon data-sentry-element="CogIcon" data-sentry-source-file="SettingsPanel.tsx" />
        <span>{t('Settings')}</span>
      </div>
      <div className="panel-content">
        <h3>{t('Account')}</h3>
        <div className="panel-content_section">
          <label>
            {t('Nickname')}
            <input disabled placeholder={t('Nickname')} type="text" value={isAuthenticatedPlayer(player) ? player.username : t('Anonymous')} />
          </label>
        </div>

        <div className="panel-content_section">
          <label>
            {t('Language')}
            <select onChange={e => {
            playerActions.setCurrentLanguage(e.target.value);
          }} value={currentLanguage}>
              {getI18nSettings().supportedLngs.map(lang => <option key={lang} value={lang}>
                  {t(lang)}
                </option>)}
            </select>
          </label>
        </div>
      </div>
    </div>;
}