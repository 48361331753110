import dayjs from 'dayjs'

export const formatTimeUntil = (timestamp: string): string => {
  const now = dayjs()
  const target = dayjs(timestamp)

  // Check for invalid dates
  if (!target.isValid()) {
    return '0s'
  }

  if (target.isBefore(now)) {
    return '0s'
  }

  const diff = target.diff(now, 'second')

  const days = Math.floor(diff / (24 * 60 * 60))
  const hours = Math.floor((diff % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((diff % (60 * 60)) / 60)
  const seconds = diff % 60

  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m`
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`
  } else {
    return `${seconds}s`
  }
}
