import { useEffect, useState } from 'react'
import { formatElapsedTime, formatRemainingTime } from 'src/utils/raceUtils'
import { formatTimeUntil } from 'src/utils/formatTimeUntil'
import { useClientSideTranslation } from 'src/services/i18n'
import { useGetCurrentLanguage } from 'src/store/game'
import type { Race } from 'src/types/domain/Race'

interface EventInfo {
  label: string
  getTime: () => string
}

interface UseTimerProps {
  race: Race
}

export const useTimer = (props: UseTimerProps) => {
  const { race } = props
  const { t } = useClientSideTranslation(useGetCurrentLanguage())
  const [eventInfo, setEventInfo] = useState<EventInfo | undefined>()

  useEffect(() => {
    const getEventInfo = (race: Race) => {
      const raceStartEvent = race.raceEvents.find((event) => event.eventType === 'race_started')
      const raceEndEvent = race.raceEvents.find((event) => event.eventType === 'race_ended')
      const raceScheduledEvent = race.raceEvents.find(
        (event) => event.eventType === 'race_scheduled'
      )
      const raceStartingEvent = race.raceEvents.find((event) => event.eventType === 'race_starting')

      if (race.raceEvents[0] === undefined) {
        return
      }

      switch (race.raceEvents[0].eventType) {
        case 'race_scheduled':
          return {
            label: t('Live in'),
            getTime: () => {
              const remainingTime = formatTimeUntil(raceScheduledEvent?.eventTimestamp || '')
              return remainingTime === '0s' ? t('Starting…') : remainingTime
            }
          }
        case 'race_ready':
          return { label: t('Race Status'), getTime: () => t('Betting Open') }
        case 'race_starting':
          return {
            label: t('Betting Closing'),
            getTime: () => formatRemainingTime(raceStartingEvent?.eventTimestamp || '', 5)
          }
        case 'race_started':
          return {
            label: t('Race Running'),
            getTime: () => formatElapsedTime(raceStartEvent?.eventTimestamp || '')
          }
        case 'race_ended':
          return {
            label: t('Race Ended'),
            getTime: () =>
              formatElapsedTime(
                raceStartEvent?.eventTimestamp || '',
                raceEndEvent?.eventTimestamp || ''
              )
          }
        case 'race_settled':
          return { label: t('Race Settled'), getTime: () => '' }
      }
    }

    // Fetch initial event info
    setEventInfo(getEventInfo(race))

    const intervalId = setInterval(() => {
      // Refresh the event info every second to update the time
      setEventInfo(getEventInfo(race))
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [race])

  return { eventInfo }
}
