import type { ButtonHTMLAttributes } from 'react';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
type Props = Readonly<{
  onClick: () => void;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  useDefaultClasses?: boolean;
}>;
export function Button({
  onClick,
  children,
  className,
  type = 'button',
  useDefaultClasses = true // Default to true for backward compatibility
}: Props) {
  const {
    playEffect
  } = useSoundEffect();
  return <button className={`${useDefaultClasses ? 'default-btn fullwidth' : ''} ${className ?? ''}`} onClick={() => {
    playEffect('click');
    onClick();
  }} type={type} data-sentry-component="Button" data-sentry-source-file="index.tsx">
      {children}
    </button>;
}