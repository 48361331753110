import { Brand } from 'effect'
import { z } from 'zod'

export type TrackRowDTO = {
  active: boolean
  show_in_lobby: boolean
  created_at: string
  description: string | null
  id: string
  image: string
  poster: string
  background: string
  video_preview_mp4: string | null
  video_preview_webm: string | null
  name: string
  short_name: string
} & Brand.Brand<'TrackRowDTO'>

export const TrackRowDTORawSchema = z.object({
  active: z.boolean(),
  show_in_lobby: z.boolean(),
  created_at: z.string(),
  description: z.string().nullable(),
  id: z.string(),
  image: z.string(),
  poster: z.string(),
  background: z.string(),
  video_preview_mp4: z.string().nullable(),
  video_preview_webm: z.string().nullable(),
  name: z.string(),
  short_name: z.string()
})

export const brandToTrackRowDTO = Brand.nominal<TrackRowDTO>()

export const TrackRowDTOSchema = TrackRowDTORawSchema.transform(brandToTrackRowDTO)
