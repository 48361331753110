import type { ConnectionStatus } from 'src/hooks/useConnectionStatus';
import WifiWeakIcon from 'src/app/assets/icons/ico-wifi-weak.svg';
import WifiExclamationIcon from 'src/app/assets/icons/ico-wifi-exclamation.svg';
export function ConnectionStatusDot({
  quality
}: {
  quality: ConnectionStatus['connectionQuality'];
}) {
  if (quality === 'good') return null;
  const iconsMap = {
    warning: <WifiWeakIcon className="connection-status-icon connection-status-icon--warning" />,
    poor: <WifiExclamationIcon className="connection-status-icon connection-status-icon--poor" />
  };
  return <div className="connection-status-wrapper" data-sentry-component="ConnectionStatusDot" data-sentry-source-file="ConnectionStatusDot.tsx">{iconsMap[quality]}</div>;
}