import { useState, useEffect } from 'react'

export function useFullscreenAvailable(): boolean {
  const [isFullscreenAvailable, setIsFullscreenAvailable] = useState<boolean>(false)

  useEffect(() => {
    if (!document.fullscreenEnabled) {
      setIsFullscreenAvailable(false)
      return
    }

    if (typeof window !== 'undefined') {
      // Check if the Permissions API is available and supports the fullscreen permission
      const checkPermission = async () => {
        if (navigator.permissions && navigator.permissions.query) {
          try {
            // Try querying the 'fullscreen' permission
            const result = await navigator.permissions.query({
              name: 'fullscreen' as PermissionName
            })
            setIsFullscreenAvailable(result.state === 'granted' || result.state === 'prompt')
          } catch (error) {
            // If there's an error (likely due to Safari), fall back to setting it as available
            setIsFullscreenAvailable(true)
          }
        }
      }

      void checkPermission()
    }
  }, [])

  return isFullscreenAvailable
}
