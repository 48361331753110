'use client';

import { useRouter } from 'next/navigation';
import { GameTopToolbar } from 'src/components/GameTopToolbar/GameTopToolbar';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
import { useGetIsSidebarOpen, useUIActions } from 'src/store/ui';
type Props = Readonly<{
  showLogo?: boolean;
}>;
export function GameTopToolbarContainer({
  showLogo = true
}: Props) {
  const router = useRouter();
  const {
    sidebarOpenToggled
  } = useUIActions();
  const isSidebarOpen = useGetIsSidebarOpen();
  const {
    playEffect
  } = useSoundEffect();
  const handleToggleSidebar = () => {
    sidebarOpenToggled();
    playEffect('click');
  };
  const goToBack = () => {
    router.push('/');
    playEffect('click');
  };
  return <GameTopToolbar goToBack={goToBack} isSidebarOpen={isSidebarOpen} onToggleSidebar={handleToggleSidebar} showLogo={showLogo} data-sentry-element="GameTopToolbar" data-sentry-component="GameTopToolbarContainer" data-sentry-source-file="GameTopToolbarContainer.tsx" />;
}