'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
export function InitDataDog() {
  useEffect(() => {
    const initDD = () => {
      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT as string,
        env: process.env.NEXT_PUBLIC_ENV as string,
        site: 'datadoghq.eu',
        // forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: process.env.VERCEL_PROJECT_PRODUCTION_URL as string,
        usePartitionedCrossSiteSessionCookie: true,
        useSecureSessionCookie: process.env.NEXT_PUBLIC_ENV === 'production' ? true : false,
        beforeSend() {
          return ['production', 'staging'].includes(process.env.NEXT_PUBLIC_ENV as string);
        }
      });
    };
    initDD();
  }, []);
  return <></>;
}