import type { Race } from 'src/types/domain/Race';
import type { RaceEvent } from 'src/types/domain/RaceEvent';
import type { Marble } from 'src/types/domain/Marble';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
import { use3dHoverEffect } from 'src/hooks/use3dHoverEffect';
import { GameCardBackgroundImage } from './components/GameCardBackgroundImage';
import { GameCardTimer } from './components/GameCardTimer';
import { track } from 'effect/Supervisor';
type Props = Readonly<{
  id: string;
  name: string;
  laps: number;
  totalMarbles: number;
  trackName: string;
  trackImage: string;
  trackModeName: string;
  latestWinners?: {
    raceId: string;
    marble: Marble;
  }[];
  latestRace: Race;
  latestEvent: RaceEvent;
  previewThumbnail?: string;
  videoPreviewMp4: string | null;
  videoPreviewWebm: string | null;
  onTrackClick: () => void;
  isLoading: boolean;
}>;
export function GameCard({
  id,
  name,
  trackName,
  trackImage,
  trackModeName,
  latestWinners = [],
  latestRace,
  latestEvent,
  previewThumbnail,
  videoPreviewMp4,
  videoPreviewWebm,
  onTrackClick,
  isLoading
}: Props) {
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  } = use3dHoverEffect();
  const {
    playEffect
  } = useSoundEffect();
  const handleClick = () => {
    if (!isScheduled && !isLoading) {
      playEffect('click');
      onTrackClick();
    }
  };
  const isScheduled = latestEvent.eventType === 'race_scheduled';
  return <div aria-label={`View details for ${trackName}`} className={`game-lobby-races_entry ${latestEvent.eventType} ${isScheduled ? 'scheduled' : ''} ${isLoading ? 'loading' : ''}`} style={{
    order: isScheduled ? 1 : 0
  }} key={id} onClick={handleClick} onKeyDown={event => {
    if (!isScheduled && !isLoading && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      handleClick();
    }
  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} role={isScheduled ? 'presentation' : 'button'} tabIndex={isScheduled ? -1 : 0} data-sentry-component="GameCard" data-sentry-source-file="GameCard.tsx">
      <div className="entry-content_inner">
        <div className="entry-image">
          <GameCardBackgroundImage previewThumbnail={previewThumbnail} trackImage={trackImage} trackName={trackName} videoPreviewMp4={videoPreviewMp4} videoPreviewWebm={videoPreviewWebm} data-sentry-element="GameCardBackgroundImage" data-sentry-source-file="GameCard.tsx" />
          {isLoading && <div className="loading-overlay">
              <div className="loading-spinner"></div>
            </div>}
        </div>
        <div className="entry-bottom">
          <div className="track-name">{trackName}</div>
          <div className="track-details">
            <span>{name}</span>
            <span> | </span>
            <span>{trackModeName}</span>
          </div>
          <div className="track-events">
            <GameCardTimer race={latestRace} data-sentry-element="GameCardTimer" data-sentry-source-file="GameCard.tsx" />
          </div>
        </div>
      </div>
    </div>;
}