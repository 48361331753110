'use client';

import { Howl } from 'howler';
import { useEffect } from 'react';
import { useGetCurrentEffects, useGetEffectsVolume, useGetIsEffectsMuted, useGetIsMasterMuted, useGetMasterVolume, useSoundActions } from 'src/store/sound';
const soundEffects = new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/effects.mp3'],
  volume: 1,
  sprite: {
    cancel: [0, 391.83673469387753],
    chip: [2000, 290.4761904761903],
    click: [4000, 208.97959183673453],
    marbleSelected: [6000, 432.01814058956955],
    marbleSelectedReverse: [6000, 432.01814058956955],
    panelReveal: [8000, 888.0045351473917],
    panelRevealReverse: [8000, 888.0045351473917],
    reward: [10000, 1988.2993197278918],
    betPlaced: [13000, 679.183673469387],
    switch: [15000, 696.0090702947853]
  }
});
export function SoundEffects() {
  const soundStoreActions = useSoundActions();
  // Volume state
  const masterVolume = useGetMasterVolume();
  const effectsVolume = useGetEffectsVolume();
  const currentSoundEffects = useGetCurrentEffects();
  const volume = effectsVolume * masterVolume;

  // Muted state
  const isMasterMuted = useGetIsMasterMuted();
  const isEffectsMuted = useGetIsEffectsMuted();
  const muted = isEffectsMuted || isMasterMuted;

  // Play sound effects
  useEffect(() => {
    if (currentSoundEffects.length > 0) {
      currentSoundEffects.forEach(effect => {
        soundEffects.play(effect.type);
        soundStoreActions.clearEffect(effect.id);
      });
    }
  }, [currentSoundEffects, soundStoreActions]);

  // Set volume
  useEffect(() => {
    soundEffects.volume(muted ? 0 : volume);
  }, [volume, muted]);
  return null;
}