'use client';

import { Howl } from 'howler';
import { useEffect } from 'react';
import { useGetMasterVolume, useGetMusicVolume, useGetIsMusicMuted, useGetIsMasterMuted, useGetCurrentSongIndex, useSoundActions, useGetIsPlayingMusic } from 'src/store/sound';
import { useInitialUserInteraction } from 'src/hooks/useInitialUserInteraction';
const songs = [new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/songs/background-melody-01.m4a', '/sounds/songs/background-melody-01.mp3']
}), new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/songs/background-melody-02.m4a', '/sounds/songs/background-melody-02.mp3']
}), new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/songs/background-melody-03.m4a', '/sounds/songs/background-melody-03.mp3']
}), new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/songs/background-melody-04.m4a', '/sounds/songs/background-melody-04.mp3']
}), new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/songs/background-melody-05.m4a', '/sounds/songs/background-melody-05.mp3']
})];
const MUSIC_VOLUME_MODIFIER = 0.15;
const setNextSong = (currentSongIndex: number, musicSongChanged: (song: number) => void, songs: Howl[]) => {
  const nextIndex = (currentSongIndex + 1) % songs.length;
  musicSongChanged(nextIndex);
};
export function Music() {
  const soundStoreActions = useSoundActions();
  const isPlayingMusic = useGetIsPlayingMusic();
  const currentSongIndex = useGetCurrentSongIndex();
  const currentSong = songs[currentSongIndex];

  // Volume state
  const masterVolume = useGetMasterVolume();
  const musicVolume = useGetMusicVolume() * MUSIC_VOLUME_MODIFIER;
  const volume = musicVolume * masterVolume;

  // Muted state
  const isMasterMuted = useGetIsMasterMuted();
  const isMusicMuted = useGetIsMusicMuted();
  const muted = isMusicMuted || isMasterMuted;

  // Play music on initial interaction
  useInitialUserInteraction(() => {
    if (Boolean(currentSong) && !isPlayingMusic) {
      soundStoreActions.playMusic();
      soundStoreActions.unmuteMaster();
    }
  });

  // Set initial song
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * songs.length);
    setNextSong(randomIndex, soundStoreActions.musicSongChanged, songs);
  }, [soundStoreActions]);

  // Play current song
  useEffect(() => {
    if (isPlayingMusic) {
      currentSong?.play();
    }
  }, [currentSong, isPlayingMusic]);

  // Play next song when current song ends
  useEffect(() => {
    currentSong?.on('end', () => {
      setNextSong(currentSongIndex, soundStoreActions.musicSongChanged, songs);
    });
    return () => {
      currentSong?.off('end');
    };
  }, [currentSong, currentSongIndex, soundStoreActions]);

  // Set volume
  useEffect(() => {
    if (currentSong) {
      currentSong.volume(muted ? 0 : volume);
    }
  }, [currentSong, volume, muted]);
  return null;
}