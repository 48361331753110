import type { ConnectionStatus } from 'src/hooks/useConnectionStatus';
export function ConnectionDetails({
  status
}: {
  status: ConnectionStatus;
}) {
  const getConnectionMessage = (): string => {
    if (!status.isOnline) return 'Offline';
    const baseMessage = `${status.latency.toFixed(0)}ms`;
    const qualityMessage = {
      good: ' (Good)',
      warning: ' (High Latency)',
      poor: ' (Poor Connection)'
    }[status.connectionQuality];
    return `${baseMessage}${qualityMessage || ''}`;
  };
  return <div className="entry" data-sentry-component="ConnectionDetails" data-sentry-source-file="ConnectionDetails.tsx">
      <span>Connection:</span>
      <span className={`connection-status-text connection-status-text--${status.connectionQuality}`}>
        {getConnectionMessage()}
      </span>
    </div>;
}