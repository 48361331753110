'use client';

import { Howl } from 'howler';
import { useEffect } from 'react';
import { useGetCurrentNotifications, useGetEffectsVolume, useGetIsEffectsMuted, useGetIsMasterMuted, useGetMasterVolume, useSoundActions } from 'src/store/sound';
const notifications = new Howl({
  autoplay: false,
  preload: true,
  src: ['/sounds/notifications/notifications.mp3'],
  volume: 1,
  sprite: {
    notificationRaceEnded: [0, 1749.342403628118],
    notificationRaceReady: [3000, 2517.3469387755104],
    notificationRaceScheduled: [7000, 2560.0000000000005],
    notificationRaceSettled: [11000, 1856.0090702947853],
    notificationRaceSettledLost1: [14000, 2005.3514739229038],
    notificationRaceSettledLost2: [18000, 2752.01814058957],
    notificationRaceSettledWon1: [22000, 2112.018140589569],
    notificationRaceStarted: [26000, 2197.3469387755104],
    notificationRaceStartedAlt: [30000, 1962.6757369614509],
    notificationRaceStarting: [33000, 1813.3333333333326],
    notification: [36000, 3526.5306122448974]
  }
});
const NOTIFICATIONS_VOLUME_MODIFIER = 0.1;

// ! Notifications use the effects volume
export function SoundNotifications() {
  const soundStoreActions = useSoundActions();
  // Volume state
  const masterVolume = useGetMasterVolume();
  const effectsVolume = useGetEffectsVolume();
  const currentSoundNotifications = useGetCurrentNotifications();
  const volume = effectsVolume * masterVolume * NOTIFICATIONS_VOLUME_MODIFIER;

  // Muted state
  const isMasterMuted = useGetIsMasterMuted();
  const isEffectsMuted = useGetIsEffectsMuted();
  const muted = isEffectsMuted || isMasterMuted;

  // Play sound notifications
  useEffect(() => {
    if (currentSoundNotifications.length > 0) {
      currentSoundNotifications.forEach(notification => {
        notifications.play(notification.type);
        soundStoreActions.clearNotification(notification.id);
      });
    }
  }, [currentSoundNotifications, soundStoreActions]);

  // Set volume
  useEffect(() => {
    notifications.volume(muted ? 0 : volume);
  }, [volume, muted]);
  return null;
}