import { useGetIsMasterMuted } from 'src/store/sound';
import VolumeMuteIcon from 'src/app/assets/icons/ico-volume-mute.svg';
import VolumeIcon from 'src/app/assets/icons/ico-volume.svg';
import { useGetIsAudioSettingsOpen, useUIActions } from 'src/store/ui';
import { Button } from './Button';
import AudioControlsDialog from './AudioControlsDialog';
import { FullScreenButtonContainer } from './FullScreenButton';
export default function BroadcastControls() {
  const uiActions = useUIActions();
  const isAudioSettingsOpen = useGetIsAudioSettingsOpen();
  const isMasterMuted = useGetIsMasterMuted();
  const handleAudioClick = () => {
    uiActions.audioSettingsToggled();
  };
  return <>
      <div className="broadcast-controls">
        <FullScreenButtonContainer data-sentry-element="FullScreenButtonContainer" data-sentry-source-file="BroadcastControls.tsx" />
        <Button className={`game-toolbar-btn ${isAudioSettingsOpen ? 'active' : ''}`} onClick={handleAudioClick} useDefaultClasses={false} data-sentry-element="Button" data-sentry-source-file="BroadcastControls.tsx">
          {isMasterMuted ? <VolumeMuteIcon className="text-red" /> : <VolumeIcon />}
        </Button>
      </div>
      <AudioControlsDialog isOpen={isAudioSettingsOpen} onClose={() => {
      uiActions.audioSettingsOpened(false);
    }} data-sentry-element="AudioControlsDialog" data-sentry-source-file="BroadcastControls.tsx" />
    </>;
}