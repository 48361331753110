import { useState, useEffect, useCallback, useRef } from 'react';
import { useGetCurrentLanguage, useGetGameConfig } from 'src/store/game';
import type { MarbleGame } from 'src/types/domain/Game';
import useDeviceOrientation from 'src/hooks/useDeviceOrientation';
import CogIcon from 'src/app/assets/icons/ico-cog.svg';
import ClipboardListCheckIcon from 'src/app/assets/icons/ico-clipboard-list-check.svg';
import HistoryIcon from 'src/app/assets/icons/ico-history.svg';
import TimesIcon from 'src/app/assets/icons/ico-times.svg';
import WalletIcon from 'src/app/assets/icons/ico-wallet.svg';
import CocktailIcon from 'src/app/assets/icons/ico-cocktail.svg';
import ExternalLinkIcon from 'src/app/assets/icons/ico-external-link.svg';
import FlagCheckeredIcon from 'src/app/assets/icons/ico-flag-checkered.svg';
import UserIcon from 'src/app/assets/icons/ico-user.svg';
import { isAuthenticatedPlayer } from 'src/types/domain/Player';
import type { Player } from 'src/types/domain/Player';
import { useClientSideTranslation } from 'src/services/i18n';
import { BetHistoryPanelContainer } from '../BetHistoryPanel/BetHistoryPanelContainer';
import { ClearButton } from '../ClearButton';
import { ExternalLink } from '../ExternalLink';
import { SettingsPanel } from './components/SettingsPanel';
import { PastRacesPanel } from './components/PastRacesPanel';
import { RulesPanel } from './components/RulesPanel';
import { transformPresets, useViewportScale } from 'src/hooks/useViewportScale';
import { useSoundEffect } from 'src/hooks/useSoundEffect';
type Props = Readonly<{
  activePanel: string;
  setActivePanel: (panel: string) => void;
  isOpen: boolean;
  onClose: () => void;
  player: Player;
  showStatsAndRules: boolean;
  game?: MarbleGame;
}>;
export default function RightSidebar({
  activePanel,
  setActivePanel,
  isOpen,
  onClose,
  player,
  showStatsAndRules,
  game
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const {
    externalLobbyUrl,
    externalDepositUrl
  } = useGetGameConfig();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const {
    playEffect
  } = useSoundEffect();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const scaleStyle = useViewportScale({
    transform: transformPresets.basic
  });
  const handleClickOutside = useCallback((event: MouseEvent) => {
    // Check if the click was on the toolbar button
    const toolbarButton = document.querySelector('.toolbar-button');
    const isToolbarButtonClick = toolbarButton?.contains(event.target as Node);
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node) && isOpen && !isToolbarButtonClick // Ignore clicks on the toolbar button
    ) {
      onClose();
    }
  }, [isOpen, onClose]);
  useEffect(() => {
    // Only add the listener if the sidebar is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      // Slight delay to ensure visibility is set before animation starts
      setTimeout(() => {
        setIsAnimating(true);
      }, 10);
    } else {
      setIsAnimating(false);
      // Wait for animation to finish before hiding
      setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
  }, [isOpen]);
  const handleBackClick = () => {
    playEffect('click');
    setActivePanel('none');
  };
  const panels = {
    settings: {
      label: t('Settings'),
      icon: CogIcon,
      content: <SettingsPanel onBackClick={handleBackClick} />
    },
    ...(showStatsAndRules ? {
      betHistory: {
        label: t('Bet History'),
        icon: HistoryIcon,
        content: isAuthenticatedPlayer(player) ? <BetHistoryPanelContainer onBackClick={handleBackClick} /> : null
      },
      pastRaces: {
        label: t('Past Races'),
        icon: FlagCheckeredIcon,
        content: <PastRacesPanel onBackClick={handleBackClick} />
      },
      ...(game && {
        rules: {
          label: t('Rules'),
          icon: ClipboardListCheckIcon,
          content: <RulesPanel game={game} onBackClick={handleBackClick} />
        }
      })
      // stats: {
      //   label: 'Stats',
      //   icon: ChartBarIcon,
      //   content: <StatsPanel />
      // }
    } : {})
  };
  const handlePanelClick = (panelKey: string) => {
    if (panelKey === 'none' && activePanel === 'none') {
      return;
    }
    setActivePanel(panelKey !== activePanel ? panelKey : 'none');
  };
  const hasActivePanel = activePanel !== 'none';
  if (!isVisible) return null;
  const activePanelClass = hasActivePanel ? 'panel-active' : '';
  const isAnimatingClass = isAnimating ? 'open' : '';
  return <div className={`marbles-right-sidebar visible ${isAnimatingClass} ${activePanelClass}`} ref={sidebarRef} style={scaleStyle} data-sentry-component="RightSidebar" data-sentry-source-file="RightSidebar.tsx">
      <div className="marbles-right-sidebar__inner">
        <div className="marbles-right-sidebar__content no-scrollbar">
          <div className="marbles-right-sidebar__player">
            <div className="player-name">
              <div className="avatar">
                <UserIcon data-sentry-element="UserIcon" data-sentry-source-file="RightSidebar.tsx" />
              </div>
              <div className="name">
                <span className="label">{t('Player')}</span>
                <span className="value">
                  {isAuthenticatedPlayer(player) ? player.username : t('Anonymous')}
                </span>
              </div>
            </div>

            <div className="external_actions">
              {externalLobbyUrl && <ExternalLink href={externalLobbyUrl} label={t('Casino Lobby')}>
                  <span className="icon">
                    <CocktailIcon />
                  </span>
                  <span className="link-icon">
                    <ExternalLinkIcon />
                  </span>
                </ExternalLink>}

              {externalDepositUrl && <ExternalLink href={externalDepositUrl} label={t('Deposit')}>
                  <span className="icon">
                    <WalletIcon />
                  </span>
                  <span className="link-icon">
                    <ExternalLinkIcon />
                  </span>
                </ExternalLink>}
            </div>
          </div>
          {hasActivePanel ? panels[activePanel as keyof typeof panels]?.content : null}
        </div>
        <div className={`marbles-right-sidebar__nav ${activePanelClass}`}>
          <button className="close" onClick={onClose} type="button">
            <span className="inner">
              <span className="icon">
                <TimesIcon data-sentry-element="TimesIcon" data-sentry-source-file="RightSidebar.tsx" />
              </span>
            </span>
          </button>
          {Object.entries(panels).map(([key, {
          label,
          icon: Icon
        }]) => <ClearButton className={activePanel === key ? 'active' : ''} key={key} onClick={() => {
          handlePanelClick(key);
        }}>
              <span className="label">{label}</span>
              <span className="inner">
                <span className="icon">
                  <Icon />
                </span>
              </span>
            </ClearButton>)}
        </div>
      </div>
    </div>;
}