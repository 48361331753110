import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { GameCardContainer } from 'src/components/GameCard';
import FlashIcon from 'src/app/assets/icons/ico-flash.svg';
type Props = Readonly<{
  title?: string;
  gameIds: string[];
}>;
export function ShowGames({
  title,
  gameIds
}: Props) {
  const router = useRouter();
  const [loadingTrackId, setLoadingTrackId] = useState<string | null>(null);
  const handleTrackClick = (gameId: string) => {
    setLoadingTrackId(gameId);
    router.push(`/${gameId}`);
  };
  return <>
      <div className="section-heading">
        <div className="icon">
          <FlashIcon data-sentry-element="FlashIcon" data-sentry-source-file="ShowGames.tsx" />
        </div>
        <h2>{title}</h2>
      </div>
      <div className="game-lobby-races">
        {gameIds.map(gameId => <GameCardContainer gameId={gameId} key={gameId} onTrackClick={() => handleTrackClick(gameId)} isLoading={loadingTrackId === gameId} />)}
      </div>
    </>;
}